<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col sm:w-full md:w-full d-theme-dark-bg">
              <div class="p-8 login-tabs-container">
                <span v-if='is_sent'>
                    <div>
                        {{ successMessage }}
                    </div>
                    <vs-divider></vs-divider>
                    <div class='flex center'>
                        <vs-button :to="{name:'login'}" class="text-center">Back to Login</vs-button>
                    </div>
                </span>
                <span v-else>
                    
                    <div class="vx-card__title mb-4">
                      <h4 class="mb-4">Forgot your password ?</h4>
                      <p>Please enter your email</p>
                    </div>

                    <div style="padding-bottom:30px;">
                      <vs-input
                          name="email"
                          icon-no-border
                          icon="icon icon-user"
                          icon-pack="feather"
                          v-model="email"
                          class="w-full"/>

                      <vs-divider></vs-divider>
                      <vs-button @click="submitConfirm()" class="float-right">Submit</vs-button>
                      <vs-button @click="$router.back()" class="float-right mr-2" color='warning'>Back</vs-button>
                    </div>
                </span>

              </div>
            </div>

          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

import {mapActions} from 'vuex'
export default{
  data() {
    return {
      email: "",
      successMessage: "",
      is_sent : false,
    }
  },
  created() {
  },
  methods:{
    
    async submitConfirm(){
      this.$vs.loading()
      let payload = {email:this.email}
      this.$vs.loading()
      try {
          let data = await this.$store.dispatch('auth/confirmation', payload)
          this.is_sent = true
          this.successMessage = data.data.message
          this.$vs.notify({
                title: 'Success',
                text: data.data.message,
                color: 'success'
          })
          this.$vs.loading.close()
      } catch (error) {
        this.$vs.loading.close()
        if(error.status == 422) {
            this.$vs.notify({
                title: 'Oops!',
                text: error.data.email[0],
                color: 'danger'
            })
        } else {
            if(error.data) {
                this.$vs.notify({
                    title: 'Oops!',
                    text: error.data.message,
                    color: 'danger'
                })
            } else {
                this.$vs.notify({
                    title: 'Oops!',
                    text: JSON.stringify(error),
                    color: 'danger'
                })                    
            }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#page-login {
  .social-login-buttons {
    .bg-facebook { background-color: #1551b1 }
    .bg-twitter { background-color: #00aaff }
    .bg-google { background-color: #4285F4 }
    .bg-github { background-color: #333 }
  }
}
.logo {
  max-width: 300px;
}
</style>
